@import "~@aws-amplify/ui-react/styles.css";

/* Root */

html {
  background: #192952;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 40em) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 70em) {
  html {
    font-size: 16px;
  }
}

body {
  background: white;
  color: #192952;
  font: normal 100%/1.5rem "Rubik", sans-serif;
  min-height: 100vh;
  overflow-x: hidden;
  overscroll-behavior-x: contain;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
  }
}

* {
  box-sizing: border-box;
}

/* Interactive */

a {
  color: #192952;
  text-decoration: underline;
  transition: color 200ms ease;
}
a:hover,
a:focus {
  color: #0052a4;
}

button {
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.5rem;
  padding: 0;
}

/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  margin: 0;
}

/* Content */

p {
  margin: 0 0 2rem;
  max-width: 40em;
}

/* Screen Reader Text */

.srt {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
